import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Säulen- und Liniendiagramme müssen nicht langweilig und statisch sein. Moderne JavaScript-Bibliotheken
ermöglichen das Erstellen von animierten, interaktiven und barrierefreien Diagrammen.`}</p>
    <p>{`Ich bin ein großer Fan von `}<a parentName="p" {...{
        "href": "https://www.highcharts.com/"
      }}>{`Highcharts`}</a>{`, einer JavaScript-Bibliothek für
SVG-basierte Diagramme. Es gibt mehrere Bibliotheken, welche Highcharts in die auf Komponenten basierte
Architektur von React und Angular integrieren. In meiner Demo-App zeige ich dir, wie du auch das Event-Handling
von Highcharts mit Angular-Komponenten verbinden kannst.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.33333333333333%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQBBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAHpKMSJQmv/xAAaEAACAgMAAAAAAAAAAAAAAAABAgADEBES/9oACAEBAAEFAlTUtBYL0RDj/8QAFREBAQAAAAAAAAAAAAAAAAAAABH/2gAIAQMBAT8Bqv/EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AYj/xAAZEAADAAMAAAAAAAAAAAAAAAAAESEgMVH/2gAIAQEABj8CptcRcP/EABoQAQADAAMAAAAAAAAAAAAAAAEAESEQMYH/2gAIAQEAAT8hVC/CLlVHCiu5Z1N8f//aAAwDAQACAAMAAAAQhy//xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8QEO27/8QAFREBAQAAAAAAAAAAAAAAAAAAERD/2gAIAQIBAT8QAT//xAAbEAEAAgIDAAAAAAAAAAAAAAABABFBUSEx0f/aAAgBAQABPxA+LoLh8PMzWXyEBNc7NLOBuVP/2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Ein Kugelschreiber zeigt auf ein Blatt Papier mit Säulen- und Liniendiagrammen.",
          "title": "Ein Kugelschreiber zeigt auf ein Blatt Papier mit Säulen- und Liniendiagrammen.",
          "src": "/static/9bda174046cf27e57273b612b77814ad/e5166/pexels-lukas-charts-on-paper.jpg",
          "srcSet": ["/static/9bda174046cf27e57273b612b77814ad/f93b5/pexels-lukas-charts-on-paper.jpg 300w", "/static/9bda174046cf27e57273b612b77814ad/b4294/pexels-lukas-charts-on-paper.jpg 600w", "/static/9bda174046cf27e57273b612b77814ad/e5166/pexels-lukas-charts-on-paper.jpg 1200w", "/static/9bda174046cf27e57273b612b77814ad/b17f8/pexels-lukas-charts-on-paper.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Foto: © Lukas / pexels.com`}</em></p>
    <h2>{`Demo App: Auswahl eines Tabs, wenn eine Säule im Diagramm geklickt wird`}</h2>
    <p>{`Meine Demo-App basiert auf Angular 13, Highcharts und `}<a parentName="p" {...{
        "href": "https://www.npmjs.com/package/highcharts-angular"
      }}>{`highcharts-angular`}</a>{`,
einer minimalen Bibliothek, die Highcharts für Angular kapselt. Ein Säulendiagramm zeigt die Nutzung primärer
Desktop-Screenreader. Mehrere Tabs bieten zusätzliche Informationen zu den Screenreadern. Wenn eine Säule im Diagramm
geklickt wird, wird das zugehörige Tab ausgewählt. Probiere es aus:`}</p>
    <iframe src="https://alexlehner86.github.io/highcharts-event-mgmt-in-angular/" title="Demo Page Highcharts/Angular Event Management"></iframe>
    <p>{`Also, wie habe ich das geschafft?`}</p>
    <h3>{`Schritt 1: Diagramm-Optionen definieren und das Diagramm rendern`}</h3>
    <p>{`Die Highcharts-Bibliothek ist extrem praktisch. Du definierst einfach deine Diagramm-Optionen und Highcharts erledigt
den Rest. In meiner Demo-App habe ich ein Diagramm mit zwei Säulenreihen definiert:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`export const EXAMPLE_CHART: Highcharts.Options = {
    series: [
        {
            data: [
                [ScreenreaderName.Jaws, 53.7],
                [ScreenreaderName.Nvda, 30.7],
                [ScreenreaderName.VoiceOver, 6.5],
                [ScreenreaderName.ZoomTextFusion, 4.7],
                [ScreenreaderName.Other, 4.4],
            ],
            name: 'Worldwide',
            type: 'column',
        },
        {
            data: [
                [ScreenreaderName.Jaws, 40.2],
                [ScreenreaderName.Nvda, 41.6],
            ],
            name: 'Europe',
            type: 'column',
        }
    ],
    subtitle: {
        text: '',
    },
    title: {
        text: 'Primary desktop/laptop screen reader (2021)',
    },
    xAxis: {
        categories: [
            ScreenreaderName.Jaws,
            ScreenreaderName.Nvda,
            ScreenreaderName.VoiceOver,
            ScreenreaderName.ZoomTextFusion,
            ScreenreaderName.Other,
        ],
    },
    yAxis: {
        title: {
            text: 'Usage in percent',
        }
    }
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Dann stelle ich in der `}<InlineCode mdxType="InlineCode">{`AppComponent`}</InlineCode>{` die Diagramm-Optionen und die Highcharts-Bibliothek bereit:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`import * as Highcharts from 'highcharts';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    public chartOptions = EXAMPLE_CHART;
    public highchartsLib = Highcharts;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Als letztes übergebe ich im HTML-Template die Bibliothek und die Diagramm-Optionen an
die `}<InlineCode mdxType="InlineCode">{`<`}{`highcharts-chart`}{`>`}</InlineCode>{` Komponente:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<highcharts-chart
  [Highcharts]="highchartsLib"
  [options]="chartOptions"
  (chartInstance)="addCustomPropertiesToChart($event)"
></highcharts-chart>`}</code>{`
        `}</deckgo-highlight-code>
    <h3>{`Schritt 2: Benutzerdefinierte Eigenschaften der Diagramm-Instanz hinzufügen`}</h3>
    <p>{`Die `}<InlineCode mdxType="InlineCode">{`<`}{`highcharts-chart`}{`>`}</InlineCode>{` Komponente stellt die
Ausgabe-Eigenschaft `}<InlineCode mdxType="InlineCode">{`chartInstance`}</InlineCode>{` bereit. Über diese erhalten wir die
Instanz des Diagramms, nachdem dieses erstellt wurde. Dieser Instanz fügen wir eine Funktion als
benutzerdefinierte Eigenschaft hinzu:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`public addCustomPropertiesToChart(
    chartInstance: Highcharts.Chart
): void {
    (chartInstance as MyExampleChart)
        .onSeriesPointClick =
        (name) => this.selectScreenreaderTab(name);
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Das `}<InlineCode mdxType="InlineCode">{`Chart`}</InlineCode>{` Interface von Highcharts kennt keine Methode
namens `}<InlineCode mdxType="InlineCode">{`onSeriesPointClick`}</InlineCode>{`. Deshalb definieren wir ein eigenes Interface,
welches diese benutzerdefinierte Eigenschaft hinzufügt:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`export interface MyExampleChart extends Highcharts.Chart {
    onSeriesPointClick: (name: ScreenreaderName) => void;
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Nun bietet unsere Diagramm-Instanz eine Methode an, die direkt mit der Eltern-Komponente des Diagramms
verknüpft ist. Ein `}<span lang="en">{`Event Handler`}</span>{` des Diagramms kann diese Methode aufrufen, um
zum Beispiel zu kommunizieren, dass ein konkreter Datenpunkt angeklickt worden ist.`}</p>
    <h3>{`Schritt 3: Die benutzerdefinierte Methode in einem `}<span lang="en">{`Event Handler`}</span>{` aufrufen`}</h3>
    <p>{`Mithilfe der Eigenschaft `}<InlineCode mdxType="InlineCode">{`plotOptions`}</InlineCode>{` in den Diagramm-Optionen definieren wir
einen `}<span lang="en">{`Click Event Handler`}</span>{` für
`}<a parentName="p" {...{
        "href": "https://api.highcharts.com/highcharts/plotOptions.series.point.events.click"
      }}>{`alle Datenpunkte`}</a>{`:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`click: function(this) {
    const chart = this.series.chart as MyExampleChart;
    chart.onSeriesPointClick(this.name as ScreenreaderName);
}`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Der `}<span lang="en">{`Event Handler`}</span>{` holt sich die Diagramm-Instanz, die unserem benutzerdefinierten
Interface `}<InlineCode mdxType="InlineCode">{`MyExampleChart`}</InlineCode>{` entspricht. Nun können wir die benutzerdefinierte
Methode `}<InlineCode mdxType="InlineCode">{`onSeriesPointClick`}</InlineCode>{` aufrufen und somit das zum Datenpunkt gehörige
Screenreader-Tab in der Applikation auswählen.`}</p>
    <h3>{`Schritt 4: Mache dein Diagramm barrierefrei`}</h3>
    <p>{`Highcharts bietet großartige Features für mehr Barrierefreiheit an. Du kannst etwa Farben als
`}<a parentName="p" {...{
        "href": "https://www.highcharts.com/docs/chart-design-and-style/pattern-fills"
      }}>{`Musterfüllungen`}</a>{` definieren. Das hilft besonders
Menschen mit eingeschränktem Farbsehen. Diese profitieren davon, wenn durch Farbe vermittelte Informationen auch auf
andere visuelle Weise verfügbar sind.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`color: {
    pattern: {
        backgroundColor: '#96dbe7',
        color: '#080e38',
        height: 10,
        path: 'M 0 0 L 10 10 M 9 - 1 L 11 1 M - 1 9 L 1 11',
        width: 10,
    }
},`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Mithilfe der Eigenschaft `}<InlineCode mdxType="InlineCode">{`linkedDescription`}</InlineCode>{` kannst du das Diagramm mit einem
HTML-Element verknüpfen, das den Inhalt des Diagramms beschreibt:`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`accessibility: {
    enabled: true,
    linkedDescription: '#highcharts-description-1',
},`}</code>{`
        `}</deckgo-highlight-code>
    <h2>{`Fazit`}</h2>
    <p>{`Kombiniere Angular und Highcharts, um wunderschöne, barrierefreie Diagramme mit minimalem Aufwand zu gestalten.
Mithilfe von benutzerdefinierten Eigenschaften kannst du das `}<span lang="en">{`Event Handling`}</span>{` von
Highcharts mit der auf Komponenten basierten Architektur von Angular verbinden, um noch interaktivere
Nutzer:innen-Erlebnisse zu erschaffen.`}</p>
    <h2>{`Ressourcen`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://alexlehner86.github.io/highcharts-event-mgmt-in-angular/"
        }}>{`Die Live-Demo "Highcharts Event Management In Angular"`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/alexlehner86/highcharts-event-mgmt-in-angular"
        }}>{`Quellcode der Demo-App auf Github`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.npmjs.com/package/highcharts"
        }}>{`Highcharts-Bibliothek`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.npmjs.com/package/highcharts-angular"
        }}>{`Highcharts-Angular-Bibliothek`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      